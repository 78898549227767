import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="P" transform="translate(33.000000, 14.000000)">
        <path
          d="M5.28409 58V14.3636H20.0284C23.4517 14.3636 26.25 14.9815 28.4233 16.2173C30.6108 17.4389 32.2301 19.0938 33.2812 21.1818C34.3324 23.2699 34.858 25.5994 34.858 28.1705C34.858 30.7415 34.3324 33.0781 33.2812 35.1804C32.2443 37.2827 30.6392 38.9588 28.4659 40.2088C26.2926 41.4446 23.5085 42.0625 20.1136 42.0625H9.54545V37.375H19.9432C22.2869 37.375 24.169 36.9702 25.5895 36.1605C27.0099 35.3509 28.0398 34.2571 28.679 32.8793C29.3324 31.4872 29.6591 29.9176 29.6591 28.1705C29.6591 26.4233 29.3324 24.8608 28.679 23.483C28.0398 22.1051 27.0028 21.0256 25.5682 20.2443C24.1335 19.4489 22.2301 19.0511 19.858 19.0511H10.5682V58H5.28409Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
